// components/Feature_collections.js
import React from "react";
import HeadLine from "../headLine";
import Feature_collections_carousel from "../carousel/Feature_collection_carousel";
import Image from "next/image";
import { useRouter } from "next/router";

const Feature_collections = ({ bgWhite = false, printables }) => {
  const router = useRouter();

  // Handle Load More click
  const handleLoadMore = () => {
    router.push('/3d-model');
  };

  return (
    <div>
      <section className="py-24 relative">
        <div className="container">
          {bgWhite && (
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <Image
                src="/images/gradient_light.jpg"
                alt="gradient"
                className="h-full"
                layout="fill"
              />
            </picture>
          )}
          <div className="container">
            <HeadLine
              image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f4a5.png"
              text="Trending Models"
              classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
            />
            <div className="relative">
              {/* Pass printables data as props */}
              <Feature_collections_carousel printables={printables} />
            </div>
          </div>
          <div className="mt-10 text-center">
            <button
              onClick={handleLoadMore}
              className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            >
              Load More
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feature_collections;
