// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar } from "swiper";
// import "swiper/css";
// import "swiper/css/navigation";
// import Image from "next/image";
// import "tippy.js/dist/tippy.css";
// import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
// import Feature_collections_data from "../../data/Feature_collections_data";
// import Link from "next/link";

// const Feature_collections_carousel = () => {
//   return (
//     <>
//       <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
//       {/* <Swiper
//         modules={[Navigation, Pagination, Scrollbar]}
//         breakpoints={{
//           // when window width is >= 640px
//           640: {
//             slidesPerView: 1,
//             spaceBetween: 20,
//           },
//           // when window width is >= 768px
//           768: {
//             slidesPerView: 2,
//             spaceBetween: 20,
//           },
//           900: {
//             slidesPerView: 3,
//             spaceBetween: 20,
//           },
//           1100: {
//             slidesPerView: 4,
//             spaceBetween: 30,
//           },
//         }}
//         navigation={{
//           nextEl: ".swiper-button-next",
//           prevEl: ".swiper-button-prev",
//         }}
//         className=" card-slider-4-columns !py-5"
//       > */}

//         {Feature_collections_data.map((item) => {
//           const {
//             id,
//             bigImage,
//             subImage1,
//             subImage2,
//             subImage3,
//             userImage,
//             title,
//             itemsCount,
//             userName,
//           } = item;

//           const itemLink = bigImage
//             .split("/")
//             .slice(-1)
//             .toString()
//             .split("_")
//             .slice(1, 2)
//             .toString();

//           return (
//             // <SwiperSlide key={id}>
//               <article key={id}>
//                   <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
//                   <Link href={`/item/item_${itemLink}`}>
//                     <a className="flex space-x-[0.625rem]">
//                       <figure className="w-full h-full">
//                         <Image
//                           src={bigImage}
//                           alt="item 1"
//                           className="rounded-[0.625rem]"
//                           width={240}
//                           height={240}
//                           objectFit="cover"
//                           layout="responsive"
//                         />
//                       </figure>
//                       {/* <span className="flex w-1/3 flex-col space-y-[0.625rem]">
//                         <img
//                           src={subImage1}
//                           alt="item 1"
//                           className="h-full rounded-[0.625rem] object-cover"
//                           loading="lazy"
//                         />

//                         <img
//                           src={subImage2}
//                           alt="item 1"
//                           className="h-full rounded-[0.625rem] object-cover"
//                           loading="lazy"
//                         />
//                         <img
//                           src={subImage3}
//                           alt="item 1"
//                           className="h-full rounded-[0.625rem] object-cover"
//                           loading="lazy"
//                         />
//                       </span> */}
//                     </a>
//                   </Link>

//                   <Link href={`/item/item_${itemLink}`}>
//                     <a className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white">
//                       {title}
//                     </a>
//                   </Link>

//                   <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
//                     <div className="flex flex-wrap items-center">
//                       <Link href={`/item/item_${itemLink}`}>
//                         <a className="mr-2 shrink-0">
//                           <img
//                             src={userImage}
//                             alt="owner"
//                             className="h-5 w-5 rounded-full"
//                           />
//                         </a>
//                       </Link>
//                       <span className="dark:text-jacarta-400 mr-1">by</span>
//                       <Link href={`/item/item_${itemLink}`}>
//                         <a className="text-accent">
//                           <span>{userName}</span>
//                         </a>
//                       </Link>
//                     </div>
//                     <span className="dark:text-jacarta-300 text-sm">
//                       {itemsCount} views
//                     </span>
//                   </div>
//                 </div>
//               </article>
//             // </SwiperSlide>
//           );
//         })}
//       </div>
//       {/* </Swiper> */}

//       {/* <!-- Slider Navigation --> */}
//       {/* <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
//         <MdKeyboardArrowLeft />
//       </div>
//       <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
//         <MdKeyboardArrowRight />
//       </div> */}
//     </>
//   );
// };

// export default Feature_collections_carousel;

import React from "react";
import Link from "next/link";
import Image from "next/image";

const Feature_collections_carousel = ({ printables }) => {
  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {!printables || printables.length === 0 ? (
        <p>Loading...</p> // Display loading state while data is being fetched
      ) : (
        printables.map((item) => {
          const {
            _id,
            id,
            bigImage,
            urlTitle,
            designerImage = "/images/avatars/creator_2.png",
            title,
            itemsCount,
            designerName,
            designLink, // Design link from the database
          } = item;

          const itemLink = bigImage
            .split("/")
            .slice(-1)
            .toString()
            .split("_")
            .slice(1, 2)
            .toString();

          return (
            <article key={_id}>
              <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                {/* Use the designLink in the href with target="_blank" */}
                <Link 
                  href={{
                    pathname : `/3d-model/${urlTitle}-${id}`,
                  }} 
                passHref>
                  <a
                    className="flex space-x-[0.625rem]"
                    // target="_blank"
                    rel="noopener noreferrer" // Add this for security
                  >
                    <figure className="w-full h-full">
                      <Image
                        src={bigImage}
                        alt="item 1"
                        className="rounded-[0.625rem]"
                        width={240}
                        height={240}
                        objectFit="cover"
                        layout="responsive"
                      />
                    </figure>
                  </a>
                </Link>

                {/* Use the designLink in the href for the title */}
                {/* <Link href={designLink} passHref> */}
                <Link
                  href={{
                    pathname : `/3d-model/${urlTitle}-${id}`,
                  }} 
                >
                  <a
                    className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white"
                    // target="_blank"
                    rel="noopener noreferrer" // Add this for security
                  >
                    {title}
                  </a>
                </Link>

                <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                  <div className="flex flex-wrap items-center">
                    <Link 
                      href={{
                        pathname : `/3d-model/${urlTitle}-${id}`,
                      }} 
                    passHref>
                      <a
                        className="mr-2 shrink-0"
                        // target="_blank"
                        rel="noopener noreferrer" // Add this for security
                      >
                        <img
                          src={designerImage}
                          alt="owner"
                          className="h-5 w-5 rounded-full"
                        />
                      </a>
                    </Link>
                    <span className="dark:text-jacarta-400 mr-1">by</span>
                    <Link 
                      href={{
                        pathname : `/3d-model/${urlTitle}-${id}`,
                      }} 
                    passHref>
                      <a
                        className="text-accent"
                        // target="_blank"
                        rel="noopener noreferrer" // Add this for security
                      >
                        <span>{designerName}</span>
                      </a>
                    </Link>
                  </div>
                  <span className="dark:text-jacarta-300 text-sm">
                    {itemsCount} views
                  </span>
                </div>
              </div>
            </article>
          );
        })
      )}
    </div>
  );
};

export default Feature_collections_carousel;
