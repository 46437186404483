'use server'

export async function updatePrintable(id, updateObj = {}) {
    try{
        const putMethod = {
          method: 'PUT', // Method itself
          headers: {
            'Content-type': 'application/json; charset=UTF-8' // Indicates the content 
          },
          body: JSON.stringify(updateObj) // We send data in JSON format
          }
          
        // make the HTTP put request using fetch api
    
        const res = await fetch(`/api/printables/${id}`, putMethod);
    
        // console.log('put res', res)

        const data = await res.json();

        if (!res.ok) {
            return { notFound: true };
        }

        return {
            props: { product: data?.data || null },
        };
        }catch(err){
          console.log(err)
        }
}

export async function searchRelatdDesigns(id1, id2, type = "random" , searchBy = "category") {
    try{
        const putMethod = {
          method: 'GET', // Method itself
          headers: {
            'Content-type': 'application/json; charset=UTF-8' // Indicates the content 
          }
          }
          
        // make the HTTP put request using fetch api

        let searchQuery = ""
        searchQuery += id1 ? `?id=${id1}` : '';
        searchQuery += id2 ? `?&id=${id2}` : '';
        searchQuery += searchQuery.length ? `&type=${type}&searchBy=${searchBy}` : '';    
    
        const res = await fetch(`/api/printables/search` + searchQuery);
    
        // console.log('put res', res)

        const data = await res.json();

        if (!res.ok) {
            return { notFound: true };
        }

        return data?.data;

        }catch(err){
          console.log(err)
        }
}

export async function reportDesign(updateObj) {
    try{
        const postMethod = {
          method: 'POST', // Method itself
          headers: {
            'Content-type': 'application/json; charset=UTF-8' // Indicates the content 
          },
          body: JSON.stringify(updateObj) // We send data in JSON format
          }
          
        // make the HTTP put request using fetch api
    
        const res = await fetch(`/api/report`, postMethod);
    
        // console.log('put res', res)

        const data = await res.json();

        return data;

        }catch(err){
          console.log(err)
        }
}