import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { bidsData } from "../../data/bids_data";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { bidsModalShow } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import Likes from "../likes";

const BidsCarousel = ({designs}) => {
  const dispatch = useDispatch();
  const handleclick = () => {
    console.log("clicked on ");
  };
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={false}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {designs && designs?.length ? designs.map((item) => {
          const { _id, id, bigImage, title, bid_number, eth_number, react_number, userImage, userName, itemsCount, urlTitle } =
            item;
          // const itemLink = image
          //   .split("/")
          //   .slice(-1)
          //   .toString()
          //   .replace(".jpg", "");
          return (
            <SwiperSlide className="text-white" key={id}>
              <article>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                  <figure>
                    {/* {`item/${itemLink}`} */}
                    <Link 
                    href={{
                        pathname : `/3d-model/${urlTitle}-${id}`,
                        query : {
                          _id
                        }
                      }} >
                      <a>
                        <div className="w-full">
                          <Image
                            src={bigImage}
                            alt={title}
                            height={230}
                            width={230}
                            layout="responsive"
                            objectFit="cover"
                            className="rounded-[0.625rem] w-full"
                            loading="lazy"
                          />
                        </div>
                      </a>
                    </Link>
                  </figure>
                  <div className="mt-4 flex items-center justify-between">
                    <Link 
                    href={{
                        pathname : `/3d-model/${urlTitle}-${id}`,
                        query : {
                          _id
                        }
                      }} >
                      <a>
                        <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                          {title}
                        </span>
                      </a>
                    </Link>
                   
                  </div>

                  <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                  <div className="flex flex-wrap items-center">
                    <Link 
                    href={{
                        pathname : `/3d-model/${urlTitle}-${id}`,
                        query : {
                          _id
                        }
                      }}  passHref>
                      <a
                        className="mr-2 shrink-0"
                        // target="_blank"
                        rel="noopener noreferrer" // Add this for security
                      >
                        <img
                          src={userImage}
                          alt="owner"
                          className="h-5 w-5 rounded-full"
                        />
                      </a>
                    </Link>
                    <span className="dark:text-jacarta-400 mr-1">by</span>
                    <Link 
                    href={{
                        pathname : `/3d-model/${urlTitle}-${id}`,
                        query : {
                          _id
                        }
                      }}  passHref>
                      <a
                        className="text-accent"
                        // target="_blank"
                        rel="noopener noreferrer" // Add this for security
                      >
                        <span>{userName}</span>
                      </a>
                    </Link>
                  </div>
                  <span className="dark:text-jacarta-300 text-sm">
                    {itemsCount} views
                  </span>
                </div>
                </div>

               
              </article>
            </SwiperSlide>
          );
        })
        :
        null
      }
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
